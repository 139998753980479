import React from "react";
import { Header } from "../components/header";
import { Layout } from "../components/layout";
import { PresseKontakt } from "../components/presse-kontakt";
import SEO from "../components/seo";
import { EasyGrid } from "../components/styles/EasyGrid";
import { LinkButton } from "../components/styles/LinkButton";
import { Vita } from "../components/vita";

const IndexPage = () => (
  <Layout>
    <SEO
      title="Über Winfried Neun"
      keywords={[`gatsby`, `application`, `react`]}
    />
    <Header small>
      <>
        <div className="circle-text">
          <h1>
            Über
            <br /> Winfried Neun
          </h1>
        </div>
        <div
          className="circle"
          style={{
            backgroundImage: `url(${require("../images/presse/w_neun.jpg")})`
          }}
        />
        <EasyGrid className="links">
          <LinkButton
            href="https://kom-neun.de/"
            target="_blank"
            style={{ background: "#0E5C8C", border: "1px solid #0E5C8C" }}
          >
            Mein Unternehmen
          </LinkButton>
          <LinkButton
            href="http://neunsight.de/"
            target="_blank"
            style={{ background: "#C20B21" }}
          >
            Meine Fachzeitschrift
          </LinkButton>
        </EasyGrid>
      </>
    </Header>

    <Vita />

    <PresseKontakt />
  </Layout>
);

export default IndexPage;
