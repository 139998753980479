import React from "react";
import { MaxWidth, centerFlex, READ_WIDTH } from "../utils/style";
import styled from "styled-components";
import Img from "gatsby-image";
import { StaticQuery, useStaticQuery, graphql } from "gatsby";
import RTF from "./rtf";
import { TiltImage } from "./tilt-image";
import { LinkButton } from "./styles/LinkButton";
import { EasyGrid } from "./styles/EasyGrid";
interface Props {}

const Div = styled.div`
  margin: 3rem 0;
`;
const Content = styled.div`
  display: flex;
  align-items: center;
  > * {
    margin: 0 1rem;
  }

  .right {
    flex-grow: 1;
  }

  .left {
    ${centerFlex}
  }
`;

export const Vita = (props: Props) => {
  const data = useStaticQuery(graphql`
    {
      contentfulHtml(uid: { eq: "vitae" }) {
        content {
          json
        }
      }
    }
  `);

  return (
    <MaxWidth greyGradient id="vita" maxWidth={READ_WIDTH}>
      <Div>
        <h2 style={{ textAlign: "center", marginBottom: "3rem" }}>Vita</h2>
        <RTF content={data.contentfulHtml.content.json} />
      </Div>
    </MaxWidth>
  );
};
